import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '~apollo/url';
import { CONNECT_ACCOUNT_WITH_ACCESS_TOKEN } from '~graphql/brand/mutation';
import { PlatformCodeEnum } from '~graphqlTypeScript/graphql';
import { OAuthTAuthTokenPayloadProps } from '~views/brand/OAuthTAuthTokenPayloadProps';

const exchangeUrl = `${backendUrl}/montu/api/v1/exchange_auth_code`;

export default function InstagramLoginCallback() {
  const navigate = useNavigate();
  const [addSocialNetwork] = useMutation(CONNECT_ACCOUNT_WITH_ACCESS_TOKEN);
  const fetchCode = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const undecodedState = JSON.parse(atob(state || ''));
    if (code) {
      const queryParams = new URLSearchParams({
        code,
        client_id: '1078882880613416',
        grant_type: 'authorization_code',
        redirect_uri: `${window.location.origin}/app/instagram-login-callback`,
        state: state || '',
      });
      const response = await fetch(`${exchangeUrl}?${queryParams.toString()}`, {
        method: 'POST',
      });
      if (response.ok) {
        const payload: OAuthTAuthTokenPayloadProps = await response.json();
        const {
          access_token: accessToken,
          issued_at: issuedAt,
          expires_in: expiresIn,
          refresh_token: refreshToken,
          refresh_token_expires_in: refreshTokenExpiresIn,
          scopes,
        } = payload;
        addSocialNetwork({
          variables: {
            accessTokenInput: {
              clientId: '1078882880613416',
              provider: PlatformCodeEnum.Instagram,
              issuedAt,
              accessToken,
              expiresIn,
              refreshToken,
              refreshTokenExpiresIn,
              permissions: scopes || [],
              brandId: undecodedState.brandId,
            },
            brandId: undecodedState.brandId,
          },
          onCompleted: () => {
            navigate(undecodedState.finalRedirectUrl);
          },
        });
      }
    }
  };
  useEffect(() => {
    fetchCode();
  }, []);

  return <div>Procesando autenticación...</div>;
}
