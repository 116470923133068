import { graphql } from '~graphqlTypeScript';

export const CONNECT_ACCOUNT_WITH_ACCESS_TOKEN = graphql(`
  mutation connectAccountWithAccessToken(
    $accessTokenInput: AccessTokenInput!
    $brandId: ID!
  ) {
    user {
      connectAccountWithAccessToken(
        accessTokenInput: $accessTokenInput
      ) {
        accounts {
          ... SocialNetworkUserFragment
        }
          query {
            brandResource {
              brand(
                id: $brandId
              ) {
                ... BrandFragment
                brandsocialnetworkuserSet {
                  ... BrandSocialNetworkUserFragment
                  socialNetworkUser {
                    ... SocialNetworkUserFragment
                  }
                }
              }
            }
          }
      }
    }  
  }
`);

export const INSTALL_SHOPIFY_AUTH_CODE = graphql(`
  mutation installShopifyAuthCode ($authInput: ShopifyAuthInput!) {
    brand {
      installShopifyAuthCode(authInput: $authInput) {
        brand {
          ... BrandFragment
          brandsocialnetworkuserSet {
            ... BrandSocialNetworkUserFragment
            socialNetworkUser {
              ... SocialNetworkUserFragment
            }
          }
        }
      }
    }
  }
`);

export const DELETE_BRAND = graphql(`
  mutation deleteBrand($brandId: ID!) {
    brand {
      delete(brandId: $brandId) {
        success
      }
    }
  }
`);

export const EDIT_BRAND = graphql(`
  mutation editBrand($brandInput: EditBrandInput! $brandId: ID!) {
    brand {
      edit(brandInput: $brandInput brandId: $brandId) {
        brand {...BrandFragment}
      }
    }
  }
`);

export const TOGGLE_FAVORITE = graphql(`
  mutation toggleToFavorite($brandId: ID! $userId: ID!) {
    brand {
      addToFavorite(favorite: {brandId: $brandId userId: $userId}) {
        query {
          userResource {
            list (favoriteByBrand: $brandId id: $userId) {
              totalCount
            }
          }
        }
      }
    }
  }
`);
